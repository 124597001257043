<template>
  <div
    class="mx-auto"
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          offset-md="8"
          md="4"
        >
          <v-text-field
            v-model="search"
            :append-icon="icon.mdiMagnify"
            label="Rechercher"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Table -->
    <v-data-table
      id="list-refill"
      :headers="headers"
      :items="bondList"
      :search="search"
      :items-per-page="itemPerPage"
      divider="true"
      class="table-kitchen-sink mx-auto"
    >
      <template #[`item.num_ticket`]="{item}">
        <div class="d-flex align-center p-5">
          <div class="d-flex flex-column ms-3 ">
            <span class="font-weight-semibold text-body-1 text--primary"> N° {{ item.num_ticket }} </span>
            <span class="custom-text--small">Envoyé le : {{ formatJour(item.date) }}</span>
            <span
              v-if="item.close_date"
              class="custom-text--small custom-red--text"
            >Fermé le : {{ item }}</span>
            <v-spacer></v-spacer>
            <v-col
              cols="12"
              class="align-content-end"
            >
              <v-menu
                offset-y
                right
                :close-on-content-click="false"
                transition="slide-y-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    class="ma-2 custom-btn"
                    text
                    v-on="on"
                  >
                    <v-icon
                      class="error--text"
                    >
                      {{ icon.mdiHistory }}
                    </v-icon>
                    <v-icon>
                      {{ icon.mdiMenuDown }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-simple-table class="border-light">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-left"
                          colspan="2"
                        >
                          Transactions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(transaction, index) in item.accounting_bond_transactions"
                        :key="index"
                      >
                        <td class="text-truncate bordure">
                          {{ formatDate(transaction.created_at) }}
                        </td>
                        <td class="bordure">{{ transaction.message }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-menu>
            </v-col>
          </div>
        </div>
      </template>

      <template #[`item.amount`]="{item}">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text-truncate text--primary">Type d'opération: <span class="font-weight-semibold"> {{ (moyenPaiement(item.payment_method_credit)).type }}</span></span>
            <span class="d-block text-truncate text--primary">Compte débité: <span class="font-weight-semibold"> {{ item.compteD }} ({{ (moyenPaiement(item.payment_method_debit)).label }})</span></span>
            <span class="d-block text-truncate text--primary">Compte crédité: <span class="font-weight-semibold"> {{ item.compteC }} ({{ (moyenPaiement(item.payment_method_credit)).label }})</span></span>
            <!--            <v-btn-->
            <!--              class="mt-2 pl-5 pr-5 "-->
            <!--              color="info"-->
            <!--              rounded-->
            <!--              x-small-->
            <!--              outlined-->
            <!--              @click="gotoCautionBill(item.num_ticket)"-->
            <!--            >-->
            <!--              Consulter la facture-->
            <!--            </v-btn>-->
          </div>
        </div>
      </template>

      <template #[`item.payment_method`]="{item}">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text-truncate text--primary">Montant: <span class="font-weight-semibold custom--success"> {{ withCurrency(item.amount) }}</span></span>
<!--            <span class="d-block text-truncate text&#45;&#45;primary">Type: <span class="font-weight-semibold"> {{ (moyenPaiement(item.payment_method_credit)).type }}</span></span>-->
<!--            <span class="d-block text-truncate text&#45;&#45;primary">Service: <span class="font-weight-semibold"> {{ (moyenPaiement(item.payment_method_credit)).label }}</span></span>-->
            <span class="d-block text-truncate text--primary">Reférence: <span class="font-weight-semibold">{{ item.ref }}</span></span>
            <span class="d-block text-truncate text--primary">Reçu: <a
              :href="item.file_url"
              target="_blank"
              class="text-decoration-none custom--info"
            ><v-icon class="custom--icon">{{ icon.mdiFileDocumentOutline }}</v-icon> {{ item.file }}</a></span>
          </div>
        </div>
      </template>

      <template #[`item.state`]="{item}">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <v-chip
              v-if="item.state==1"
              class="ma-2 text-center pr-5 pl-5"
              color="#F1FFE8FF"
            >
              <b class="success--text">Traité</b>
            </v-chip>
            <v-chip
              v-else-if="item.state===0"
              class="ma-2 text-center"
              color="#FFF6ECFF"
            >
              <b class="custom-warning--text">En cours</b>
            </v-chip>
            <v-chip
              v-else
              class="ma-2 text-center"
              color="#FFECECFF"
            >
              <b class="error--text">Rejeté</b>
            </v-chip>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from '@vue/composition-api'
import {
  mdiFileDocumentOutline,
  mdiMagnify,
  mdiHistory,
  mdiMenuDown,
} from '@mdi/js'
import data from '@/views/finances/refillData'
import Http from '@/helpers/http'
import { DEPOT, VIREMENT } from '@/helpers/constants/refill'
import router from '@/router'
import moment from 'moment/moment'

export default {
  name: 'CautionList',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const search = ref('')
    const listRefill = ref([])
    const refillList = ref([])
    const bondList = ref([])
    const meansOfPayment = ref([])
    const itemPerPage = 15

    const loadPayment = () => {
      bondList.value = props.data
    }

    const loadMeansOfPayment = () => {
      Http.get('get-paymode')
        .then(response => {
          meansOfPayment.value = response.data

          console.log('response.data')
          console.log(response.data)
          loadPayment()
        })
        .catch(error => error)
    }

    const formatDate = date => moment(date).format('DD/MM/YYYY H:mm:ss')
    const formatJour = date => moment(date).format('DD/MM/YYYY')

    const moyenPaiement = code => {

      if(code !== undefined){
        const name = meansOfPayment.value.filter(i => (i.code).toUpperCase() === (code).toUpperCase())

        let type = null
        // eslint-disable-next-line radix
        if (name.length !== 0) {
          // eslint-disable-next-line radix
          if (parseInt(name[0].type) === DEPOT) {
            type = 'Versement'
            // eslint-disable-next-line radix
          } else if (parseInt(name[0].type) === VIREMENT) {
            type = 'Virement'
          }

          return { label: name[0].label, type }
        }
      }

      return { label: 'n/a', type: 'n/a' }
    }

    const bondListFormatted = computed(() => {
      console.log('bondList.value')
      console.log(bondList.value)
      bondList.value.forEach(i => {
        const paymentData = moyenPaiement(i.payment_method)
        // eslint-disable-next-line no-param-reassign
        i.type_label = paymentData.type
        // eslint-disable-next-line no-param-reassign
        i.payment_method_label = paymentData.label
      })
    })

    const gotoCautionBill = numero => {
      router.push({ name: 'caution-bill', params: { numero } })
    }

    const currency = ref('XAF')
    const withCurrency = price => `${Number(price).toLocaleString()} ${currency.value}`

    onBeforeMount(() => {
      loadMeansOfPayment()
    })

    return {
      icon: {
        mdiFileDocumentOutline,
        mdiMagnify,
        mdiHistory,
        mdiMenuDown,
      },
      search,
      itemPerPage,
      gotoCautionBill,
      headers: [
        {
          text: 'Ticket', value: 'num_ticket', divider: true,
        },
        {
          text: 'Compte', value: 'amount', divider: true,
        },
        {
          text: 'Paiement', value: 'payment_method', divider: true,
        },
        {
          text: 'État', value: 'state', divider: true,
        },
      ],
      refillList,
      bondList,
      listRefill,
      loadMeansOfPayment,
      meansOfPayment,
      loadPayment,
      moyenPaiement,
      currency,
      withCurrency,
      formatDate,
      formatJour,
      bondListFormatted,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
    }
  },
  watch: {
  },
  mounted() {

  },
  created() {

  },
  methods: {
    initialize() {
      this.refillList = JSON.parse(JSON.stringify(data))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/apps/refill.scss';

.custom--icon{
  height: 15px !important;
  width: 15px !important;
  color: #1E9FF2 !important;
}

.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.border{
  border: #0c5460 solid 1px;
}

.custom-warning--text{
  color: darkorange;
  //background-color: #ffcfcf;
}

.bordure{
  border-left:1px solid #a8a5a5;
  border-bottom:1px solid #a8a5a5;
}

.custom-btn:hover{
  background-color: transparent !important;
}
</style>
